export const CstApi = {
  ApiUrl: 'https://vives.api.assessmentq.com',
  client_id: 'API-EDU-Mp8FmKnULZsnmK2M',
  client_secret: 'c3okLDAUe4Mvlm?Gk^CGGJDAK*Yt)^LaAyH!qB%7&mA<A',
  AuthUrl: 'https://www.sign-in.education/e/connect/token',
  UsersUrl: '/V1/users',
  SessionUrl: '/V1/sessions',
  GroupUrl: '/V1/groups',
  AddUserToGroup: '/users/',
  AssessmentQ: 'https://vives.edumaticonline.com/edumatic5',
  LcribGroupId: 29856,
}

export const CstRoutes = {
  root: '/',
  login: '/login',
  contact: '/contact',
  links: '/links',
  bronnen: '/bronnen',
}

export const Slugs = {
  Home: '',
  Crisisbeheer: 'Crisisbeheer',
  CrisisIntelligence: 'CrisisIntelligence',
  CrisisOpSchool: 'CrisisOpSchool',
}
