import PropTypes from 'prop-types'

const ShowError = ({ errormessage }) => (
  <div className="alert alert-danger" role="alert">
    {errormessage}
  </div>
)
ShowError.propTypes = {
  errormessage: PropTypes.string.isRequired,
}
export default ShowError
