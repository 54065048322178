import { NavLink } from 'react-router-dom'

import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton,
  FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon,
} from 'react-share'
import { CstNavbarTxt, CstProjectTxt } from '../constants/Tekst'
import branding from '../images/branding.svg'
import bronIcon from '../images/bronnen-logo.svg'

const { url, titel } = CstProjectTxt

const Navbar = () => (
  <nav className="navbar navbar-expand" id="mainnavbar">
    <NavLink className="navbar-brand" to="/">
      <img src={branding} alt="branding" width="80" />
    </NavLink>
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item px-sm-2">
          <NavLink className="nav-link" to="/login">{CstNavbarTxt.eLearning}</NavLink>
        </li>
        <li className="nav-item px-sm-2">
          <NavLink className="nav-link" to="/links">{CstNavbarTxt.Links}</NavLink>
        </li>
        <li className="nav-item px-sm-2">
          <NavLink className="nav-link" to="/contact">{CstNavbarTxt.Ideeënbox}</NavLink>
        </li>

      </ul>

      <ul className="navbar-nav mr-right">
        <li>
          <FacebookShareButton
            url={url}
            quote={titel}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon
              size={30}
              round
            />
          </FacebookShareButton>
        </li>

        <li>
          <TwitterShareButton
            url={url}
            quote={titel}
            className="Demo__some-network__share-button"
          >
            <TwitterIcon
              size={30}
              round
            />
          </TwitterShareButton>
        </li>

        <li>

          <WhatsappShareButton
            url={url}
            titel={titel}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={30} round />
          </WhatsappShareButton>
        </li>

        <li>
          <LinkedinShareButton
            url={url}
            windowWidth={750}
            windowHeight={600}
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon
              size={30}
              round
            />
          </LinkedinShareButton>
        </li>

        <li>
          <EmailShareButton
            url={url}
            subject={titel}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon
              size={30}
              round
            />
          </EmailShareButton>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link p-0" to="/bronnen">
            <img src={bronIcon} alt="bron" width="30" />
          </NavLink>
        </li>
      </ul>

    </div>

  </nav>
)

export default Navbar
