import vivesLogo from '../images/vives-logo.svg'
import provincieWvlLogo from '../images/logo-wvl.jpg'

const Logos = () => (
  <div className="container-fluid">

    <div className="container">
      <div className="row">

        <div className="col-sm-12">
          <p className="text-center">
            <a href="http://www.vives.be"><img src={vivesLogo} alt="vives-logo" width="150" className="px-3" /></a>
            <a href="https://www.west-vlaanderen.be/">
              {' '}
              <img src={provincieWvlLogo} alt="vives-logo" width="220" className="px-3" />
            </a>
          </p>

        </div>
      </div>
    </div>
  </div>
)
export default Logos
