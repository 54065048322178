import { Player } from 'video-react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import PowtoonIntro from '../mp4/Powtoon_intro.mp4'
import PowtoonLevel1 from '../mp4/Powtoon_level1.mp4'
import PowtoonLevel2 from '../mp4/Powtoon_level2.mp4'
import PowtoonLevel3 from '../mp4/Powtoon_level3.mp4'
import PowtoonIntroPoster from '../images/introductie_platform.jpg'
import PowtoonLevel1Poster from '../images/level1_crisisbeheer.jpg'
import PowtoonLevel2Poster from '../images/level2_crisisintelligence.jpg'
import PowtoonLevel3Poster from '../images/level3_crisisopschool.jpg'

import Footer from '../components/Footer'
import Logos from '../components/Logos'
import { CstStartTxt } from '../constants/Tekst'
import { CstRoutes } from '../constants/const'

const Start = () => (
  <Fragment>
    <Header />
    <div className="container-fluid fluid-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1>{CstStartTxt.WelkomTitel}</h1>
            <p>{CstStartTxt.WelkomTxt}</p>

          </div>
          <div className="col-lg-6">
            <h1>{CstStartTxt.ToegangTitel}</h1>
            <p>{CstStartTxt.ToegangTxt}</p>
            <Link to={CstRoutes.login}>
              <button type="button" className="btn btn-dark btn-sm">{CstStartTxt.LoginRegistreer}</button>
            </Link>
          </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h1>{CstStartTxt.IntroTitel}</h1>
          <p>{CstStartTxt.IntroTxt}</p>
        </div>
        <div className="col-lg-6">

          <Player
            playsInline
            poster={PowtoonIntroPoster}
            src={PowtoonIntro}
          />
        </div>
      </div>
    </div>

    <div className="container-fluid fluid-wrapper-sub">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1>{CstStartTxt.CrisisbeheerTitel}</h1>
            <p>{CstStartTxt.CrisisbeheerTxt}</p>
          </div>
          <div className="col-lg-6">
            <Player
              playsInline
              poster={PowtoonLevel1Poster}
              src={PowtoonLevel1}
            />
          </div>

        </div>
      </div>
    </div>

    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <h1>{CstStartTxt.CrisisIntelligenceTitel}</h1>
          <p>{CstStartTxt.CrisisIntelligenceTxt}</p>
        </div>
        <div className="col-lg-6">
          <Player
            playsInline
            poster={PowtoonLevel2Poster}
            src={PowtoonLevel2}
          />
        </div>
      </div>
    </div>

    <div className="container-fluid fluid-wrapper-sub">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1>{CstStartTxt.CrisisOpSchoolTitel}</h1>
            <p>{CstStartTxt.CrisisOpSchoolTxt}</p>
          </div>
          <div className="col-lg-6">
            <Player
              playsInline
              poster={PowtoonLevel3Poster}
              src={PowtoonLevel3}
            />
          </div>

        </div>
      </div>
    </div>
    <Footer />

    <Logos />
  </Fragment>
)

export default Start
