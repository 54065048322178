const CstComplexiteit = {
  TeKort: 'Het wachtwoord is te kort, het moeten minimaal',
  TeKort2: ' tekens lang zijn.',
  GeenHoofdletter: 'Het wachtwoord moet een hoofdletter bevatten',
  GeenLetter: 'Het wachtwoord moet een letter bevatten',
  GeenCijfer: 'Het wachtwoord moet een cijfer bevatten',
  GeenSpeciaalTeken: 'Het wachtwoord moet een speciaal teken (! @ 1 $ % ^ & * | - _ ) bevatten',
}

const anUpperCase = /[A-Z]/
const aLowerCase = /[a-z]/
const aNumber = /[0-9]/
const aSpecial = /[!|@|#|$|%|^|&|*|(|)|-|_]/

const CheckComplexiteit = (checkStr, minLengte) => {
  if (checkStr.length < minLengte) throw new Error(`${CstComplexiteit.TeKort} ${minLengte} ${CstComplexiteit.TeKort2}`)
  if (!new RegExp(anUpperCase).test(checkStr)) throw new Error(`${CstComplexiteit.GeenHoofdletter} `)
  if (!new RegExp(aLowerCase).test(checkStr)) throw new Error(`${CstComplexiteit.GeenLetter} `)
  if (!new RegExp(aNumber).test(checkStr)) throw new Error(`${CstComplexiteit.GeenCijfer} `)
  if (!new RegExp(aSpecial).test(checkStr)) throw new Error(`${CstComplexiteit.GeenSpeciaalTeken} `)
}

export default CheckComplexiteit
