import { Fragment } from 'react'

import Footer from '../components/Footer'
import Logos from '../components/Logos'
import Header from '../components/Header'

import { CstContactTxt, CstProjectTxt, ICON_MAIL } from '../constants/Tekst'

const Contact = () => (
  <Fragment>

    <Header />

    <div className="container-fluid fluid-wrapper">
      <div className="container">
        <div className="row about">
          <div className="col-sm-12">
            <h1>{CstContactTxt.SuggestiesTitel}</h1>
            <p>
              {CstContactTxt.SuggestiesTxt}
              <i className={ICON_MAIL} />
              <u><a href="mailto:{CstProjectTxt.mail}">{CstProjectTxt.mail}</a></u>
            </p>
          </div>
        </div>
      </div>
    </div>

    <Footer />

    <Logos />

  </Fragment>
)

export default Contact
