/* eslint-disable max-len */

export const CstProjectTxt = {
  mail: 'info@l-crib.be',
  url: 'https://www.l-crib.be/',
  titel: 'Leerplatform Crisis- en InformatieBeheer',
}

export const CstNavbarTxt = {
  eLearning: 'E-Learning',
  Links: 'Links',
  Ideeënbox: 'Ideeënbox',
}

export const CstHomeTxt = {
  Leerplatform: 'Leerplatform',
  Uitleg: `Je bevindt je op het leerplatform van L- CRIB.  
Via het bovenstaand menu, kan je willekeurig navigeren tussen het leermateriaal. 
We raden aan om te starten met level 1 en alle oefeningen in chronologische volgorde af te werken.
Het leerplatform biedt in beide levels oefeningen per thema in diverse vormen, van meerkeuze- tot videovragen. Daarbij krijgen de gebruikers via de knop "controleer" zoveel mogelijk feedback over hun juiste en foute antwoorden, zodat ze ook op die manier hun kennis kunnen verbeteren. 
Wie de oefeningen heeft doorlopen, kan zichzelf evalueren met een test.`,
  Aangemeld: 'Je bent ingelogd, klik hier om uit te loggen.',
  Logout: 'Afmelden',
  ErrorGeenGebruiker: 'Er is geen gebruiker met deze e-mail gevonden',
}

export const CstStartTxt = {
  WelkomTitel: 'Welkom op L-CRIB',
  WelkomTxt: `L-CRIB ofwel het "Leerplatform Crisis- en InformatieBeheer" is een nieuw leerplatform om alle onderdelen van multidisciplinair crisisbeheer en
crisis intelligence te oefenen op een leuke, realistische en interactieve manier.`,
  ToegangTitel: 'Word lid!',
  ToegangTxt: 'Om toegang te krijgen tot het leermateriaal, moet je een account aanmaken.',
  LoginRegistreer: 'Login / Registreer',
  IntroTitel: 'Intro',
  IntroTxt: `Leuk dat je dit leerplatform hebt ontdekt. 
Ben je student of professional en wil je meer te weten komen over crisisbeheer en noodplanning? 
Op dit platform leer je niet enkel hoe een crisis in België wordt aangepakt en welke instanties er allemaal betrokken zijn, 
ook kan je na het doorlopen van de verschillende modules relevante informatie op een correcte manier delen. 
Kijk naar het filmpje vooraleer je van start gaat.`,

  CrisisbeheerTitel: 'Crisisbeheer in België',
  CrisisbeheerTxt: `Je maakt kennis met de manier waarop crisisbeheer in België is georganiseerd. 
Wat is een noodsituatie? 
Hoe wordt het terrein afgeschermd? 
Welke hulpdiensten helpen mee? 
Hoe ziet de coördinatie eruit? 
Op deze vragen bieden we een antwoord.`,

  CrisisIntelligenceTitel: 'Crisis intelligence',
  CrisisIntelligenceTxt: `Hoe kunnen interventiediensten en overheden snel en efficiënt reageren in een dynamische context? 
Het is erg belangrijk dat iedereen beschikt over correcte informatie. 
Welke informatie is er nodig? Hoe gaan we die verzamelen en analyseren? 
En op welke manier verspreiden we die tenslotte?`,

  CrisisOpSchoolTitel: 'Crisis op school',
  CrisisOpSchoolTxt: `Wat is een intern noodplan? 
Hoe wordt omgegaan met een crisis op school? 
Wie doet wat en draagt welke verantwoordelijkheid? 
Hoe omgaan met een brand of een bommelding op school?`,
}

export const CstFooterTxt = {
  Privacy: 'Privacy',
  PrivacyTxt: 'Privacyverklaring | Hogeschool VIVES ',
  PrivacyLink: 'https://www.vives.be/nl/over-vives/privacyverklaring',
  Titel: 'Info project',
  Tekst: 'L-CRIB werd ontwikkeld door het Expertisecentrum Sociale Innovatie en de ICT-dienst van de Katholieke Hogeschool VIVES, met medewerking van de Provincie West-Vlaanderen en met de ondersteuning van een stuurgroep samengesteld uit mevr. Barbara Bourdeau (Politiezone RIHO – Dienst nood- en interventieplanning), dhr. Hans Desmyttere (Provinciaal opleidingscentrum voor veiligheidsdiensten, POV), mevr. Evelien De Pauw (Vakgroep Governing & Policing Security, UGent) en dhr. Arne Dormaels (Innovation Center for Security vzw, INNOS).',
  VolgOns: 'Volg ons',
  Facebook: 'facebook.com/viveshogeschool',
  Twitter: 'twittter.com/viveshogeschool',
  Website: 'vives.be',
  AdresLabel: 'Adres',
  Adres: `Katholieke Hogeschool VIVES
EC Sociale Innovatie
Doorniksesteenweg 145
8500 KORTRIJK`,
  Email: 'E-mail',
  EmaiAdres: 'info@l-crib.be',
  Onderzoekers: 'Onderzoekers',
  OnderzoeksNamen: `Julie Tieberghien
Evelien De Pauw`,
}

export const CstBronenTxt = {
  BronnenLabel: 'Bronnen',
  Bronnen: 'Dit is een overzicht van de bronnen die werden gebruikt ter onderbouwing en uitwerking van dit leerplatform.',
}

export const CstContactTxt = {
  SuggestiesTitel: 'Suggesties of ideeën?',
  SuggestiesTxt: 'Heb je suggesties  of ideeën, dan kan je contact met ons op nemen via ',
  Bedankt: 'Bedankt voor uw bericht, we nemen zo snel mogelijk contact met u op',
  Voornaam: 'Voornaam',
  Naam: 'Naam',
  Email: 'E-mail',
  Bericht: 'Bericht',
  Stuur: 'Stuur bericht',
}
export const CstErrorTxt = {
  FoutTitel: 'Er is een fout opgetreden',
  FoutInloggen: 'Gelieve te controleren indien u al dan niet bent ingelogd.',
}

export const CstAanmeldenTxt = {
  Titel: 'Login / Registreer',
  Registeren: 'Registeren',
  Aanmelden: 'Aanmelden',
  AanmeldenTxt: `Om toegang te krijgen tot het leermateriaal, moet je je eerst registreren.
Heb je nog geen account op L-CRIB, dan kan je een account aanmaken.
Je account krijgt automatisch toegang tot de Edumatic-omgeving.
Ben je je wachtwoord vergeten? Neem contact met ons op, zodat we je wachtwoord opnieuw kunnen instellen.
`,
  VervolledigRegistratie: 'Vervolledig registratie',
  RegistratieTitel: 'Extra informatie',
  RegistratieTxt: 'Om de registratie te voltooien hebben we nog enkele gegevens van jou nodig.',
  EmailAlIngebruik: 'Er is al een login met dit e-mail adres.',
  Wachtwoord: 'Wachtwoord',
  WachtwoordComplex: 'Wachtwoord (min 8 lang, letter & cijfer & teken)',
  OnvoldoendeGegevens: 'Voornaam, naam, e-mail en wachtwoord zijn verplicht om te registeren.',
  ErrorGeenGebruiker: 'Geen gebruiker gevonden met het email adres: ',
}

export const CstModulesTxt = {
  Intro: '1. Intro',
  Oefenen: '2. Oefenen',
  Test: '3. Test jezelf',
  Start: 'Start',
  CrisisbeheerTitel: 'Level 1: Crisisbeheer in België',
  CrisisIntelligenceTitel: 'Level 2: Crisis Intelligence',
  CrisisOpSchoolTitel: 'Level 3: Crisis op school',
  HomeTitel: 'Start',
  Crisisbeheer: `In level 1 maak je kennis met de manier waarop crisisbeheer in België is georganiseerd. 

  Wat is een noodsituatie? Hoe wordt het terrein afgeschermd? Welke hulpdiensten helpen mee? Hoe ziet de coördinatie eruit?',

Op deze vragen bieden we een antwoord. Via een gezonde mix van theorie en oefeningen is het de bedoeling om een optimaal leerresultaat te bereiken.
Nadat je alle oefeningen - bij voorkeur in chronologische volgorde - hebt doorlopen, kan je jezelf testen.`,

  CrisisIntelligence: `In level 2 maak je kennis met crisis intelligence. 

  Hoe kunnen interventiediensten en overheden snel en efficiënt reageren in een dynamische context? 
  Het is erg belangrijk dat iedereen beschikt over correcte informatie. Welke informatie is er nodig? 
  Hoe gaan we die verzamelen en analyseren? En op welke manier verspreiden we die tenslotte?

Op deze vragen bieden we een antwoord.
Via een gezonde mix van theorie en oefeningen is het de bedoeling om een optimaal leerresultaat te bereiken.
Nadat je alle oefeningen - bij voorkeur in chronologische volgorde - hebt doorlopen, kan je jezelf testen.`,

  CrisisOpSchool: `In level 3 maak je kennis met hoe je dient om te gaan met een crisis in een schoolcontext. 

  Dit level is specifiek ontwikkeld voor directieleden en secretariaat en maakt hen wegwijs in wat we verstaan onder de opmaak van een intern noodplan, wie welke verantwoordelijkheid hier in opneemt en welke taken hij/zij zelf dient op te nemen. 
Na het doorlopen van een algemene module zijn er twee specifieke scenario’s uitgewerkt over de aanpak van een bommelding en brand. 
Nadat je alle oefeningen hebt doorlopen, kan je je zelf testen. `,
}

export const ICON_PHONE = 'fas fa-phone-square'
export const ICON_MAIL = 'fas fa-envelope'
export const ICON_LOCATION = 'fas fa-map-marked'
export const ICON_LINKS = 'fas fa-link'
export const ICON_FACEBOOK = 'fab fa-facebook'
export const ICON_TWITTER = 'fab fa-twitter'
export const ICON_CIRCLE = 'fas fa-info-circle'
export const ICON_IDCARD = 'fas fa-id-card'
export const ICON_PRIVACY = 'fas fa-user-shield'
