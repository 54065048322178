import Home from './private/Home'
import Aanmelden from '../components/Aanmelden'
import useLcrbContext from '../LcrbContext'

const Lcrib = () => {
  const { userID } = useLcrbContext()

  return (
    <div>
      {userID !== ''
        ? (<Home />)
        : (<Aanmelden />)}
    </div>
  )
}

export default Lcrib
