import { Slugs } from '../constants/const'
import useLcrbContext from '../LcrbContext'
import { CstModulesTxt } from '../constants/Tekst'

const SubNavbar = () => {
  const { setSlug, setPublicationId } = useLcrbContext()
  return (
    <nav className="navbar navbar-expand" id="subnavbar">

      <div className="mx-auto d-sm-flex d-block flex-sm-nowrap">
        <div className="collapse navbar-collapse text-center" id="navbarsExample11">
          <ul className="navbar-nav">

            <li className="nav-item px-sm-2">
              <button
                type="button"
                className="btn "
                onClick={() => {
                  setSlug(Slugs.Home)
                  setPublicationId(null)
                }}
              >
                {CstModulesTxt.HomeTitel}
              </button>
            </li>

            <li className="nav-item px-sm-3">
              <button
                type="button"
                className="btn "
                onClick={() => {
                  setSlug(Slugs.Crisisbeheer)
                  setPublicationId(null)
                }}
              >
                {CstModulesTxt.CrisisbeheerTitel}
              </button>
            </li>

            <li className="nav-item px-sm-3">
              <button
                type="button"
                className="btn "
                onClick={() => {
                  setSlug(Slugs.CrisisIntelligence)
                  setPublicationId(null)
                }}
              >
                {CstModulesTxt.CrisisIntelligenceTitel}
              </button>
            </li>

            <li className="nav-item px-sm-3">
              <button
                type="button"
                className="btn "
                onClick={() => {
                  setSlug(Slugs.CrisisOpSchool)
                  setPublicationId(null)
                }}
              >
                {CstModulesTxt.CrisisOpSchoolTitel}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default SubNavbar
