import { Fragment, useState } from 'react'
import CheckComplexiteit from '../api/checkPassword'
import { GetUserId, CreateUser, AddUserToGroup } from '../api/assessmentQ'
import useLcrbContext from '../LcrbContext'

import ShowError from './ShowError'
import Header from './Header'
import { CstAanmeldenTxt, CstProjectTxt, CstContactTxt } from '../constants/Tekst'
import { CstApi } from '../constants/const'

const Aanmelden = () => {
  const { setuserID } = useLcrbContext()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errormessage, setErrormessage] = useState('')
  const [firstname, setFirstname] = useState('')
  const [name, setName] = useState('')
  const [registratie, setRegistratie] = useState(false)

  const handleChange = (e) => {
    setErrormessage()
    if (e.target.name === 'email') { setEmail(e.target.value); return }
    if (e.target.name === 'password') { setPassword(e.target.value); return }
    if (e.target.name === 'firstname') { setFirstname(e.target.value); return }
    if (e.target.name === 'name') { setName(e.target.value); return }
    setErrormessage(`handleChange voor een onbekende state : ${e.target.name}`)
  }

  const login = async (e) => {
    try {
      // prevents page reloading
      e.preventDefault()
      const userId = await GetUserId(email)
      // toevoeg in groep om overzicht te hebben van (externe) gebruikers van AssessmentQ
      // om bestaande logins in de groep te krijgen
      await AddUserToGroup(userId, CstApi.LcribGroupId)
      setuserID(userId)
    } catch (error) {
      setuserID('')
      setErrormessage(error.message)
    }
  }

  const RegistreerInAssessmentQ = async (e) => {
    try {
      // prevents page reloading
      e.preventDefault()
      // check verplichte velden
      if (!firstname || !name || !email) {
        setErrormessage(CstAanmeldenTxt.OnvoldoendeGegevens)
        return
      }
      //  check complex wachtwoord: letter, cijfer, teken
      CheckComplexiteit(password, 8)

      const userId = await CreateUser(email, firstname, name, password)
      // toevoeg in groep om overzicht te hebben van (externe) gebruikers van AssessmentQ
      await AddUserToGroup(userId, CstApi.LcribGroupId)
      setuserID(userId)
    } catch (error) {
      setuserID('')
      setErrormessage(error.message)
    }
  }
  return (
    <Fragment>
      <Header />
      <div className="container">
        { errormessage
            && <ShowError errormessage={errormessage} />}
      </div>

      <div className="container-fluid fluid-wrapper">
        <div className="container">
          <div className="row">

            <div className="col-lg-6">

              <h2>{CstAanmeldenTxt.Titel}</h2>

              <p className="py-2">
                {CstAanmeldenTxt.AanmeldenTxt}
                <a href={`mailto:${CstProjectTxt.mail}`}>{CstProjectTxt.mail}</a>
              </p>

            </div>

            <div className="col-lg-6">
              <row>
                <form>
                  <div className="form-group">
                    <label htmlFor="email" className="col-10">
                      {CstContactTxt.Email}
                      *
                      <input
                        id="email"
                        name="email"
                        value={email}
                        placeholder="e-mail adres"
                        onChange={(e) => handleChange(e)}
                        type="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                      />
                    </label>
                  </div>

                  <div className="form-group">
                    <label htmlFor="password" className="col-10">
                      {registratie
                        ? (
                          <span>
                            {CstAanmeldenTxt.WachtwoordComplex}
                            *
                          </span>
                        )
                        : (
                          <span>
                            {CstAanmeldenTxt.Wachtwoord}
                            *
                          </span>
                        )}

                      <input
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        placeholder={CstAanmeldenTxt.Wachtwoord}
                        onChange={(e) => handleChange(e)}
                        className="form-control"
                      />
                    </label>
                  </div>

                  {registratie && (
                    <div>
                      <div className="form-group">
                        <label htmlFor="voornaam" className="col-10">
                          {CstContactTxt.Voornaam}
                          *
                          <input
                            name="firstname"
                            value={firstname}
                            className="form-control"
                            onChange={handleChange}
                          />
                        </label>
                      </div>

                      <div className="form-group">
                        <label htmlFor="naam" className="col-10">
                          {CstContactTxt.Naam}
                          *
                          <input
                            name="name"
                            value={name}
                            onChange={handleChange}
                            className="form-control"
                          />
                        </label>
                      </div>

                      <button type="button" className="btn btn-dark btn-sm" onClick={(e) => RegistreerInAssessmentQ(e)}>
                        {CstAanmeldenTxt.VervolledigRegistratie}
                      </button>
                    </div>
                  )}

                  {!registratie && (
                    <row>
                      <button
                        type="submit"
                        onClick={login}
                        className="btn btn-dark btn-sm-5 mr"
                      >
                        {CstAanmeldenTxt.Aanmelden}
                      </button>

                      <button
                        type="button"
                        onClick={() => setRegistratie(true)}
                        className="btn btn-light offset-sm-1 btn-sm-5"
                      >
                        {CstAanmeldenTxt.Registeren}
                      </button>
                    </row>
                  )}
                </form>
              </row>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

export default Aanmelden
