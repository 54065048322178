import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import useLcrbContext from '../LcrbContext'

const OefeningenLijst = ({ oefeningen }) => {
  const { slug, setPublicationId } = useLcrbContext()
  return (
    <div className="row">
      { oefeningen.map((item) => (
        <div className="col-lg-4" key={item.id}>
          <div className="card-wrapper">
            <div className="card">
              <img className="card-img-top" src={item.image} alt={item.title} />
              <div className="card-body">
                <h5 className="card-title">
                  {`${item.id}. ${item.title}`}
                </h5>

                <p className="card-text">{item.body}</p>
                <button
                  onClick={() => { setPublicationId(item.publicationId) }}
                  type="button"
                  className="btn btn-info btn-sm"
                >
                  <i className="fas fa-play-circle" />
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  )
}
OefeningenLijst.propTypes = {
  oefeningen: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      publicationId: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default OefeningenLijst
