import './App.css'
import 'video-react/dist/video-react.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Lcrib from './pages/Lcrib'

import Start from './pages/Start'
import Navbar from './components/Navbar'
import Contact from './pages/Contact'
import Links from './pages/Links'
import Bronnen from './pages/Bronnen'

import { LcribProducer } from './LcrbContext'
import { CstRoutes } from './constants/const'

const App = () => (
  <LcribProducer>
    <BrowserRouter>
      <Navbar />
      <Routes>

        <Route exact path={CstRoutes.root} element={<Start />} />
        <Route exact path={CstRoutes.login} element={<Lcrib />} />

        <Route exact path={CstRoutes.contact} element={<Contact />} />
        <Route exact path={CstRoutes.links} element={<Links />} />

        <Route exact path={CstRoutes.bronnen} element={<Bronnen />} />
      </Routes>

    </BrowserRouter>
  </LcribProducer>
)

export default App
