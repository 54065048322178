import {
  createContext, useState, useContext, useMemo,
} from 'react'
import PropTypes from 'prop-types'

const LcrbContext = createContext()

export const LcribProducer = ({ children }) => {
  const [userID, setuserID] = useState('')
  const [slug, setSlug] = useState('')
  const [publicationId, setPublicationId] = useState(null)

  const initState = useMemo(
    () => ({
      userID,
      setuserID,
      slug,
      setSlug,
      publicationId,
      setPublicationId,
    }),
    [userID, slug, publicationId],
  )

  return (
    <LcrbContext.Provider value={initState}>
      {children}
    </LcrbContext.Provider>
  )
}

LcribProducer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const useLcrbContext = () => useContext(LcrbContext)
export default useLcrbContext
