import { CstApi } from '../constants/const'
import { AxiosGet, AxiosPost } from './api'
import { CstAanmeldenTxt } from '../constants/Tekst'

export const GetUserId = async (email) => {
  const findUserUrl = `${CstApi.UsersUrl}?$filter=email eq '${email}'`
  const user = await AxiosGet(findUserUrl)
  if (user?.value?.length === 0) throw new Error(`${CstAanmeldenTxt.ErrorGeenGebruiker} ${email}`)
  const { value } = user
  return value[0].id
}

export const SessionAccessCode = async (userId, publicationId) => {
  const data = {
    userId,
    publicationId,
    scheduleId: null,
    interfaceLanguage: 'Dutch',
    accessStart: null,
    accessStop: null,
    endTime: null,
    additionalTime: null,
    completionStatus: null,
    finishedOn: null,
  }
  const session = await AxiosPost(CstApi.SessionUrl, data)
  return session ? session.accessCode : null
}

export const CreateUser = async (email, firstname, lastname, password) => {
  const user = await AxiosPost(CstApi.UsersUrl, {
    firstname,
    lastname,
    email,
    password,
    username: email,
    sendEmail: false,
    interfaceLanguage: 'Dutch',
  })
  if (!user) throw new Error(`Gebruiker niet aangemaakt met e-mail ${email}`)

  return user.id
}

export const AddUserToGroup = async (userId, groupID) => (
  AxiosPost(`${CstApi.GroupUrl}/${groupID}${CstApi.AddUserToGroup}${userId}`)
)

export const AssessmentUrl = (sessionCode) => `${CstApi.AssessmentQ}?sessionAccessCode=${sessionCode}`
