import Header from '../components/Header'
import Footer from '../components/Footer'
import Logos from '../components/Logos'
import { CstBronenTxt } from '../constants/Tekst'

const Bronnen = () => (
  <div>
    <Header />

    <div className="container-fluid fluid-wrapper">
      <div className="container">
        <div className="row about">
          <div className="col-sm-12">
            <h1>{CstBronenTxt.BronnenLabel}</h1>
            <p>{CstBronenTxt.Bronnen}</p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>
              Bruelemans, B., Brugghemans, B. &amp; Van Mechelen, I. (2015).
              <i>Help! Een crisis. Een Praktisch model voor een professioneel crisisbeheersingsproces</i>
              . Brugge: die Keure.
            </td>
          </tr>
          <tr>
            <td>
              Brugghemans, B. &amp; Van Achte, T. (2016).
              <i>Crisis Intelligence Manual</i>
              . Geraadpleegd op 19 februari 2018, op
              <a href="https://crisisintelligence.files.wordpress.com/2016/01/160121-crisisintelligencemanual-bertbrugghemans-timvanachte.pdf" target="_blank noreferrer">
                https://crisisintelligence.files.wordpress.com/2016/01/160121-crisisintelligencemanual-bertbrugghemans-timvanachte.pdf

              </a>
            </td>
          </tr>
          <tr>
            <td>
              crisiscenterBE. (2016).
              <i>NPU2016 - Getuigenissen 13: Van veiligheidscel naar coördinatiecomité</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.youtube.com/watch?v=4U2oDrY9J5s" target="_blank noreferrer">
                https://www.youtube.com/watch?v=4U2oDrY9J5s

              </a>
            </td>
          </tr>

          <tr>
            <td>
              Crisiscentrum (2017).
              <i>Het Veiligheidsportaal</i>
              . Geraadpleegd op 19 december 2017, op
              <a href="https://centredecrise.be/nl/inhoud/het-veiligheidsportaal" target="_blank noreferrer">
                https://centredecrise.be/nl/inhoud/het-veiligheidsportaal

              </a>
            </td>
          </tr>
          <tr>
            <td>
              Crisiscentrum. (n.d.).
              {' '}
              <i>Federale fase: crisisbeheer op nationaal niveau</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://crisiscentrum.be/nl/inhoud/federale-fase-crisisbeheer-op-nationaal-niveau" target="_blank noreferrer">
                https://crisiscentrum.be/nl/inhoud/federale-fase-crisisbeheer-op-nationaal-niveau

              </a>
            </td>
          </tr>

          <tr>
            <td>
              Dormaels, A., Easton, M., Schoenaers, F. &amp; Vincent, J. (2013).
              <i>Praktische gids voor burgemeesters in nood</i>
              . Brussel: FOD Binnenlandse Zaken – Algemene Directie Crisiscentrum.
            </td>
          </tr>

          <tr>
            <td>
              Federale Overheidsdienst Binnenlandse Zaken &amp; TCD (2016).
              <i>INCIDENT &amp; CRISIS MANAGEMENT SYSTEM. 2.01 - Algemene inleiding</i>
              {' '}
              [handleiding]. Brussel: Federale Overheidsdienst Binnenlandse Zaken.
            </td>
          </tr>

          <tr>
            <td>
              Het Laatste Nieuws (2017).
              {' '}
              <i>1.000 inwoners uit huizen gezet door gifwolk</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.hln.be/nieuws/1-000-inwoners-uit-huizen-gezet-door-gifwolk~a50aea7b/" target="_blank noreferrer">https://www.hln.be/nieuws/1-000-inwoners-uit-huizen-gezet-door-gifwolk~a50aea7b/</a>
            </td>
          </tr>

          <tr>
            <td>
              Het Laatste Nieuws (2018).
              {' '}
              <i>Inwoners geraadpleegd over interventieplannen</i>
              . Geraadpleegd op 26 augustus 2019, op GoPress Academic.
            </td>
          </tr>

          <tr>
            <td>
              KB van 22 mei 2019 betreffende de noodplanning en het beheer van noodsituaties op het gemeentelijk en provinciaal niveau en betreffende de rol van de burgemeesters en de provinciegouverneurs in geval van crisisgebeurtenissen en -situaties die een coördinatie of een beheer op nationaal niveau vereisen,
              {' '}
              <i>B.S.</i>
              , 27 juni 2019.
            </td>
          </tr>

          <tr>
            <td>
              Knack (2013).
              <i>&apos;Geen gevaar voor de volksgezondheid&apos;: de giframp in Wetteren van minuut tot minuut</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.knack.be/nieuws/belgie/geen-gevaar-voor-de-volksgezondheid-de-giframp-in-wetteren-van-minuut-tot-minuut/article-normal-94923.html" target="_blank noreferrer">https://www.knack.be/nieuws/belgie/geen-gevaar-voor-de-volksgezondheid-de-giframp-in-wetteren-van-minuut-tot-minuut/article-normal-94923.html</a>
            </td>
          </tr>

          <tr>
            <td>
              Krant van West-Vlaanderen/Het Wekelijks Nieuws Waregem (2018).
              <i>Goedkeuring nood- en interventieplan</i>
              . Geraadpleegd op 26 augustus 2019, op GoPress Academic.
              {' '}
            </td>
          </tr>

          <tr>

            <td>
              Lokale Politie Antwerpen. (2018).
              <i>Ontploffing Paardenmarkt</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.youtube.com/watch?v=v9s55sqj1zk" target="_blank noreferrer">https://www.youtube.com/watch?v=v9s55sqj1zk</a>
            </td>
          </tr>

          <tr>
            <td>
              Ministeriële omzendbrief NPU-4 van 30 maart 2009 betreffende de disciplines,
              <i>B.S.</i>
              , 9 september 2009.
            </td>
          </tr>

          <tr>
            <td>
              Picsbyjonesz. (2018).
              <i>Explosie in schoolgebouw - Oefening zone Westhoek regio Polder</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.youtube.com/watch?v=gvLIpfmEr_g" target="_blank noreferer">https://www.youtube.com/watch?v=gvLIpfmEr_g</a>

            </td>
          </tr>

          <tr>
            <td>
              Provincie West-Vlaanderen.
              <i>Oefening Poseidon op 16 november 2015</i>
              . Brugge: Provincie West-Vlaanderen.
            </td>
          </tr>

          <tr>
            <td>
              Provincie West-Vlaanderen.
              <i>Foto- en beeldmateriaal Oefening Poseidon op 16 november 2015</i>
              . Brugge: Provincie West-Vlaanderen.
            </td>
          </tr>

          <tr>
            <td>
              Provincie West-Vlaanderen.
              {' '}
              <i>Opleidings- en oefensessie in het kader van de oefeningen Madame &apos;Curie&apos;, 4-5 juli 2018</i>
              . Zedelgem: Provincie West-Vlaanderen.
            </td>
          </tr>
          <tr>
            <td>
              Provincie West-Vlaanderen.
              {' '}
              <i>Terreinoefening Wheely op de internationale luchthaven Brugge - Oostende op 24 januari 2018</i>
              . Oostende: Provincie West-Vlaanderen.
            </td>
          </tr>
          <tr>
            <td>
              {' '}
              Stad Zottegem.
              <i>Oefening Bevegemse Vijvers op 18 september 2017</i>
              . Zottegem: Stad Zottegem.
              {' '}
            </td>
          </tr>
          <tr>
            <td>
              Testelmans, R. (2017).
              <i>Interoperabiliteit bij hulpdiensten</i>
              {' '}
              [eindwerk]. Ranst: Campus Vesta.
            </td>
          </tr>
          <tr>
            <td>
              Vancompernolle, R. (2018).
              {' '}
              <i>Busongeval in Aalter</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="http://www.avs.be/avsnews/busongeval-in-aalter" target="_blank noreferrer">http://www.avs.be/avsnews/busongeval-in-aalter</a>
            </td>
          </tr>
          <tr>
            <td>
              VRT Nieuws (2013).
              {' '}
              <i>Aflevering Terzake, 4 mei 2013</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.youtube.com/watch?v=RfWF2ii1fVU" target="_blank noreferrer">https://www.youtube.com/watch?v=RfWF2ii1fVU</a>
            </td>
          </tr>

          <tr>
            <td>
              VTM (2013).
              {' '}
              <i>Ongerustheid in Wetteren</i>
              {' '}
              | Telefacts | VTM. Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.youtube.com/watch?v=2MpsArclaxw" target="_blank noreferrer">https://www.youtube.com/watch?v=2MpsArclaxw</a>
            </td>
          </tr>

          <tr>
            <td>
              VTM (2016).
              {' '}
              <i>Appartementsbrand | Helden van hier: Door Het Vuur | VTM</i>
              . Geraadpleegd op 26 augustus 2019, op
              <a href="https://www.youtube.com/watch?v=0CAZ725mDEo" target="_blank" rel="noreferrer noopener">https://www.youtube.com/watch?v=0CAZ725mDEo</a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />

    </div>

    <Footer />

    <Logos />
  </div>
)

export default Bronnen
