import { Fragment } from 'react'
import Header from './Header'
import Footer from './Footer'
import Logos from './Logos'
import { CstErrorTxt } from '../constants/Tekst'

const ErrorPage = () => (
  <Fragment>
    <Header />

    <div className="container-fluid fluid-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>{CstErrorTxt.FoutTitel}</h1>
            <p>{CstErrorTxt.FoutInloggen}</p>
          </div>
        </div>
      </div>

    </div>

    <Footer />

    <Logos />
  </Fragment>
)

export default ErrorPage
