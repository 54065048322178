import { Fragment } from 'react'
import PropTypes from 'prop-types'

import CrisisbeheerData from '../data/Crisisbeheer.json'
import CrisisIntelligenceData from '../data/CrisisIntelligence.json'
import CrisisOpSchool from '../data/CrisisOpSchool.json'

import { Slugs } from '../constants/const'
import { CstModulesTxt } from '../constants/Tekst'
import useLcrbContext from '../LcrbContext'
import ErrorPage from './ErrorPage'
import OefeningenLijst from './OefeningenLijst'

const BepaalData = (slug) => {
  switch (slug) {
    case Slugs.Home:
      return {}
    case Slugs.Crisisbeheer:
      return CrisisbeheerData
    case Slugs.CrisisIntelligence:
      return CrisisIntelligenceData
    case Slugs.CrisisOpSchool:
      return CrisisOpSchool
    default:
      throw new Error(`Geen data voor slug "${slug}"`)
  }
}

const Module = ({ slug }) => {
  const Data = BepaalData(slug)
  const { intro, leerfase, testfase } = Data
  const { userID, setPublicationId } = useLcrbContext()

  return (
    <div>
      { (userID !== '' && Data !== {}) ? (
        <Fragment>
          <div className="container">
            <div className="row">
              <p>{CstModulesTxt[slug]}</p>
            </div>
            <h3>{CstModulesTxt.Intro}</h3>
            <hr />

            <div className="row">
              <div className="col-lg-4">
                <div className="card-wrapper">
                  <div className="card">
                    <img className="card-img-top" src={intro[0].image} alt={intro[0].title} />
                    <div className="card-body card-body-notext">
                      <button
                        type="button"
                        onClick={() => { setPublicationId(intro[0].publicationId) }}
                        className="btn btn-info btn-sm"
                      >
                        <i className="fas fa-play-circle" />
                        {CstModulesTxt.Start}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="container-fluid fluid-wrapper-color">

            <div className="container">
              <h3>{CstModulesTxt.Oefenen}</h3>
              <hr />
              <OefeningenLijst oefeningen={leerfase} />
            </div>
          </div>

          <div className="container">
            <h3>{CstModulesTxt.Test}</h3>
            <hr />

            <OefeningenLijst oefeningen={testfase} />

          </div>
        </Fragment>

      ) : (<ErrorPage />)}

    </div>
  )
}

Module.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default Module
