import logo from '../images/logo-lcrib.png'
import useLcrbContext from '../LcrbContext'

const Header = () => {
  const { publicationId } = useLcrbContext()
  return (
    <header className="container">
      {!publicationId && (
        <div className="row">
          <div className="col-2 col-md-3 col-lg-4" />
          <div className="col-8 col-md-6 col-lg-4"><img className="img-fluid" src={logo} alt="logo" /></div>
          <div className="col-2 col-md-3 col-lg-4" />
        </div>
      )}
    </header>
  )
}
export default Header
