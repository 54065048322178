import { Fragment } from 'react'
import Header from '../components/Header'
import niplab from '../images/links/niplab.png'
import risico from '../images/links/risico.png'
import jesip from '../images/links/jesip.png'
import ibz from '../images/links/ibz.png'
import vesta from '../images/links/campusvesta.png'
import vives from '../images/links/vives.png'
import bealert from '../images/links/bealert.png'
import beprepared from '../images/links/beprepared.png'
import fema from '../images/links/fema.png'
import Footer from '../components/Footer'
import Logos from '../components/Logos'
import kuleuven from '../images/links/kuleuven.png'
import ifv from '../images/links/ifv.png'

const Links = () => (
  <Fragment>
    <Header />

    <div className="container-fluid fluid-wrapper">
      <div className="container">
        <div className="row about">
          <div className="col-sm-12">
            <h1>Links</h1>
            <p>
              Hieronder vind je enkele nuttige links naar relevante instanties binnen het domein crisisbeheer. Er zijn er natuurlijk meer. Klik op het logo om te navigeren naar de website
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <table className="table">
        <tbody>
          <tr>
            <td>
              <a href="https://crisiscentrum.be" target="_blank" rel="noopener noreferrer">
                <img src={ibz} alt="ibz" className="img-links" width="300" />
              </a>
            </td>
            <td>
              De Algemene Directie Crisiscentrum (ADCC) staat 24u/24 ten dienste van de federale regering.
              Ze verzamelt, analyseert en verspreidt op permanente basis relevante informatie aan de beleidsmatige en uitvoerende autoriteiten.
              Bijkomend biedt het Crisiscentrum zijn infrastructuur en expertise aan bij het interdepartementaal beheer en de coördinatie van noodsituaties op nationaal niveau.
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://www.be-alert.be" target="_blank" rel="noopener noreferrer">
                <img src={bealert} alt="balert" className="img-links" width="300" />
              </a>
            </td>
            <td>
              BE-Alert is een alarmeringssysteem waarmee de overheid jou kan verwittigen in een noodsituatie.
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://www.risico-info.be" target="_blank" rel="noopener noreferrer">
                <img src={risico} alt="risico" className="img-links" width="300" />
              </a>
            </td>
            <td>
              Deze website biedt informatie aan over risico&apos;s waarmee de bevolking kan worden geconfronteerd.
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://www.diekeure.be/nl-be/professional/digitaal-aanbod/platformen/be-prepared" target="_blank" rel="noopener noreferrer">
                <img src={beprepared} alt="balert" className="img-links" width="300" />
              </a>
            </td>
            <td>
              Be Prepared is het eerste multidisciplinaire tweetalige magazine over noodplanning en crisisbeheer in België.
            </td>
          </tr>

        </tbody>
      </table>
    </div>

    <div className="container-fluid fluid-wrapper-color">
      <div className="container">
        <table className="table">
          <tbody>
            <tr>
              <td>
                <a href="https://www.vives.be" target="_blank" rel="noopener noreferrer">
                  <img src={vives} alt="vives" className="img-links" width="300" />
                </a>
              </td>
              <td>
                De Katholieke Hogeschool VIVES organiseert de Bacheloropleiding Maatschappelijke Veiligheid waar studenten vanuit een integrale en geïntegreerde aanpak onder meer worden ondergedompeld in crisisbeheer en -communicatie.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.kuleuven.be" target="_blank" rel="noopener noreferrer">
                  <img src={kuleuven} alt="kuleuven" className="img-links" width="300" />
                </a>
              </td>
              <td>
                De opleiding “Rampenmanagement” van de KU Leuven behandelt crisisbeheer vanuit een slachtoffergerichte benadering en richt zich tot artsen, gezondheidswerkers, verpleegkundigen…
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://www.campusvesta.be" target="_blank" rel="noopener noreferrer">
                  <img src={vesta} alt="ibz" className="img-links" width="300" />
                </a>
              </td>
              <td>
                Campus Vesta organiseert het postgraduaat &quot;Rampenmanagement&quot; waarbij je ontdekt hoe je op beleidsmatig en operationeel vlak een grootschalig incident vanuit een multidisciplinair kader kunt aanpakken.
              </td>
            </tr>
            <tr>
              <td>
                <a href="https://noodplanning.weebly.com" target="_blank" rel="noopener noreferrer">
                  <img src={niplab} alt="niplab" className="img-links" width="300" />
                </a>
              </td>
              <td>
                Het #NIPlab wil praktische toepassingen ontwikkelen die professionals kunnen helpen om efficiënt te werken tijdens een grootschalig incident of ramp.
              </td>
            </tr>

          </tbody>
        </table>

      </div>

    </div>

    <div className="container">

      <table className="table">
        <tbody>
          <tr>
            <td>
              <a href="https://www.jesip.org.uk" target="_blank" rel="noopener noreferrer">
                <img src={jesip} alt="jesip" className="img-links" width="300" />
              </a>
            </td>
            <td>
              {' '}
              JESIP
              <i>(Joint Emergency Services Interoperability Principles)</i>
              biedt best practices voor politie-, brandweer- en ambulancediensten in het VK om de samenwerking bij multidisciplinaire incidenten te verbeteren.
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://www.fema.gov" target="_blank" rel="noopener noreferrer">
                <img src={fema} alt="balert" className="img-links" width="300" />
              </a>
            </td>
            <td>
              De doelstelling van FEMA
              {' '}
              <i>(Federal Emergency Management Agency)</i>
              {' '}
              is om mensen te helpen voor, tijdens en na rampen die plaatsvinden binnen de VS.
            </td>
          </tr>

          <tr>
            <td>
              <a href="https://www.ifv.nl" target="_blank" rel="noopener noreferrer">
                <img src={ifv} alt="balert" className="img-links" width="300" />
              </a>
            </td>
            <td>
              Het IFV (Instituut Fysieke Veiligheid) is een Nederlandse organisatie die de veiligheidsregio’s en hun veiligheidspartners bijstaat in het verbeteren van hun dagdagelijkse werking. Het deelt kennis, biedt dienstverlening op het gebied van informatievoorziening en adviseert betrokken besturen.
            </td>
          </tr>
        </tbody>
      </table>

    </div>

    <Footer />

    <Logos />

  </Fragment>
)

export default Links
