import {
  ICON_PRIVACY, ICON_CIRCLE, ICON_IDCARD,
  CstFooterTxt, ICON_LOCATION, ICON_MAIL, ICON_LINKS, ICON_FACEBOOK, ICON_TWITTER,

} from '../constants/Tekst'

const Footer = () => (
  <footer className="container-fluid">

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <h3>
            <i className={ICON_CIRCLE} />
            {CstFooterTxt.Titel}
          </h3>
          {CstFooterTxt.Tekst}
        </div>
      </div>

      <div className="row">

        <div className="col-lg-3">
          <h3>
            <i className={ICON_LINKS} />
            {CstFooterTxt.VolgOns}
          </h3>
          <a href="https://www.facebook.com/viveshogeschool/" target="_blank" rel="noopener noreferrer">
            <i className={ICON_FACEBOOK} />
            {CstFooterTxt.Facebook}
          </a>
          <br />
          <a href="https://twitter.com/viveshogeschool" target="_blank" rel="noopener noreferrer">
            <i className={ICON_TWITTER} />
            {CstFooterTxt.Twitter}
          </a>
          <br />
          <a href="http://www.vives.be" target="_blank" rel="noopener noreferrer">
            <i className={ICON_LINKS} />
            {CstFooterTxt.Website}
          </a>
          <br />

        </div>

        <div className="col-lg-3">
          <h3>
            <i className={ICON_LOCATION} />
            {CstFooterTxt.AdresLabel}
          </h3>
          <a href="https://goo.gl/maps/isCCqkpYwcm" target="_blank" rel="noopener noreferrer">
            {CstFooterTxt.Adres}
          </a>

        </div>

        <div className="col-lg-3">
          <h3>
            <i className={ICON_MAIL} />
            {CstFooterTxt.Email}
          </h3>
          <a href={`mailto:${CstFooterTxt.EmailAdres}`}>{CstFooterTxt.EmaiAdres}</a>
          <h3>
            <i className={ICON_PRIVACY} />
            {CstFooterTxt.Privacy}
          </h3>
          <a href={`${CstFooterTxt.PrivacyLink}`}>{CstFooterTxt.PrivacyTxt}</a>
        </div>

        <div className="col-lg-3">
          <h3>
            <i className={ICON_IDCARD} />
            {CstFooterTxt.Onderzoekers}
          </h3>
          {CstFooterTxt.OnderzoeksNamen}
        </div>
      </div>

    </div>
  </footer>
)
export default Footer
