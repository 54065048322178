import { Fragment, useState, useEffect } from 'react'

import useLcrbContext from '../LcrbContext'

import ErrorPage from './ErrorPage'
import Footer from './Footer'
import Logos from './Logos'
import { AssessmentUrl, SessionAccessCode } from '../api/assessmentQ'

const Opdracht = () => {
  const { userID, publicationId } = useLcrbContext()
  const [url, setUrl] = useState()

  useEffect(() => {
    const CreateSession = async () => {
      const pub = parseInt(publicationId, 10)
      const sessionCode = await SessionAccessCode(userID, pub)
      setUrl(AssessmentUrl(sessionCode))
    }
    CreateSession()
  }, [userID, publicationId])

  return (
    <Fragment>
      {/* log=2 is loggen in de backlog */}

      {
        url !== '' ? (
          <Fragment>
            <iframe
              title="edumatic"
              width="100%"
              height="800"
              src={url}
            />
            <Footer />
            <Logos />
          </Fragment>

        ) : (

          <ErrorPage />
        )

      }

    </Fragment>
  )
}

export default Opdracht
