import { Fragment, useEffect } from 'react'
import useLcrbContext from '../../LcrbContext'
import { CstHomeTxt } from '../../constants/Tekst'

import SubNavbar from '../../components/SubNavbar'
import Footer from '../../components/Footer'
import Logos from '../../components/Logos'
import Header from '../../components/Header'
import Module from '../../components/Module'
import Opdracht from '../../components/Opdracht'

const Home = () => {
  const {
    userID, setuserID, slug, publicationId,
  } = useLcrbContext()

  const logout = () => {
    setuserID('')
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [publicationId])

  return (
    <div>
      {userID !== '' && (
        <Fragment>
          <Header />
          <SubNavbar />

          { //  module gekozen = toon alle oefeningen van de modulen
            slug !== '' && publicationId === null
            && <Module slug={slug} />
          }

          {// een oefening gekozen, toon die in de AssessmentQ player
            publicationId !== null
          && <Opdracht />
          }

          { // geen module of oefening gekozen = 'start' : toon uitloggen knop
            slug === '' && !publicationId && (
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <h1>{CstHomeTxt.Leerplatform}</h1>
                    <p>{CstHomeTxt.Uitleg}</p>
                    <p>
                      {`${CstHomeTxt.Aangemeld}  `}
                      <button
                        type="button"
                        className="btn btn-dark btn-sm"
                        onClick={() => logout()}
                      >
                        {CstHomeTxt.Logout}
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            )
          }
          <Footer />

          <Logos />

        </Fragment>
      )}
    </div>
  )
}

export default Home
