import axios from 'axios'

import { CstApi } from '../constants/const'

export const AxiosPost = async (url, postData) => {
  const fullUrl = `${CstApi.ApiUrl}${url}`
  try {
    const config = { headers: {} }
    // deze post functie wordt ook gebruikt om het access token op te halen
    // voorkom een los door de url te vergelijken met de aut url
    if (url !== CstApi.AuthUrl) {
    // eslint-disable-next-line no-use-before-define
      const accessToken = await getAccessToken()
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    // console.debug(`config: ${JSON.stringify(config)}`)
    // console.debug(`post data: ${JSON.stringify(postData)}`)

    const result = await axios.post(fullUrl, postData, config)
    // succes (Ok of Created)
    if (result.status === 200 || result.status === 201) {
      return await Promise.resolve(result.data)
    }

    return await Promise.reject(new Error(`FOUT API POST: ${result.status} ${result.data}`))
  } catch (error) {
    console.error(error)
    const { response } = error
    if (!response) {
      return Promise.reject(new Error(`FOUT: geen antwoord op ${fullUrl} : ${error.message}`))
    }
    if (response.status === 400 || response.status === 422) {
      // Bad Request --> foutmeldingen van api uit antwoord halen zodat ze kunnen getoond worden
      const { data } = response
      // const foutmeldingen = Array.isArray(data.reasons) ? data.reasons.reduce((acc, current) => `${acc} / ${current.message}`, '') : data.message
      let foutmeldingen = ''
      data.reasons.forEach((reason) => { foutmeldingen += `\n ${reason.message}` })
      return Promise.reject(new Error(`Fout(en) in api POST op ${fullUrl} : ${foutmeldingen}`))
    }

    return Promise.reject(new Error(`FOUT: ${response.status} - ${error.message}`))
  }
}

const getAccessToken = async () => {
  const params = new URLSearchParams()
  params.append('grant_type', 'client_credentials')
  params.append('client_id', CstApi.client_id)
  params.append('client_secret', CstApi.client_secret)
  params.append('scope', 'assessmentQApi')

  const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  try {
    const result = await axios.post(CstApi.AuthUrl, params, config)
    return result.data.access_token
  } catch (error) {
    console.error(error)
    Promise.reject(new Error(`Kan access token niet ophalen: ${error}`))
    return null
  }
}

export const AxiosGet = async (url) => {
  const fullUrl = `${CstApi.ApiUrl}${url}`
  try {
    const accessToken = await getAccessToken()
    if (!accessToken) return null
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    const result = await axios.get(fullUrl, config)
    if (result.status === 200) {
      return await Promise.resolve(result.data)
    }
    return await Promise.reject(
      new Error(`FOUT API GET: ${result.status} ${result.data} - api url = ${fullUrl}`),
    )
  } catch (error) {
    console.error(error.message)
    const { response } = error
    if (!response) {
      console.error(error)
      return Promise.reject(
        new Error(`FOUT: geen antwoord: ${error.message} - api url = ${fullUrl}`),
      )
    }
    if (response.status === 400) {
      // Bad Request --> foutmeldingen van api uit antwoord halen zodat ze kunnen getoond worden
      const { data } = response
      const foutmeldingen = Array.isArray(data) ? data.reduce((acc, current) => acc + current) : data
      return Promise.reject(
        new Error(`Fout(en) in api GET : ${foutmeldingen} - api url = ${fullUrl}`),
      )
    }
    return Promise.reject(
      new Error(`FOUT: ${error.message} - api url = ${fullUrl}`),
    )
  }
}
